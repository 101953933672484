import { EventEmitter, ListenerFn } from "eventemitter3";

import { highEndBlurryRecognition } from "..";
import { Barcode } from "./barcode";
import { BrowserHelper } from "./browserHelper";
import { DataCaptureLoader } from "./dataCaptureLoader";
import { ScanSettings } from "./scanSettings";
import { DataCaptureSentMessageData, DataCaptureWorker, dataCaptureWorkerBlob } from "./workers/dataCaptureWorker";

type EventName = "blurryTablesUpdate";

class BlurryRecognitionPreloaderEventEmitter extends EventEmitter<EventName> {}

export class BlurryRecognitionPreloader {
  private static readonly writableDataPath: string = "/scandit_sync_folder_preload";
  private static readonly fsObjectStoreName: string = "FILE_DATA";
  // From AndroidLowEnd
  private static readonly defaultBlurryTableFiles: string[] = [
    "/281f654b8ff82daa99ad885ef39a15fb.scandit", // codabar
    "/2c53cab9a0737960a56ec66ae2a1c2cd.scandit", // codabar
    "/6b5c52b06ec25af4ac80a807f08c8a22.scandit", // codabar
    "/a161ee7d1b0a5c1f3cf6fbdf41d544da.scandit", // code32, code39
    "/c22ac7d324d8076de6c6a20667cb58fb.scandit", // code32, code39
    "/db921bb2d0f06e25180139366579b318.scandit", // code32, code39
    "/53f7125006c6641b34eed19c3863e42a.scandit", // code93
    "/6de91450426ad609398ffc0dd417066c.scandit", // code93
    "/de892fb0f0b231aa877beb05ef628982.scandit", // code93
    "/27efecb40cc701f1568a100081473470.scandit", // code128
    "/37c247f983a341588eca92f4095982f6.scandit", // code128
    "/9fa42646d1b7ab87f5dbc66c7423275e.scandit", // code128
    "/423b33a061cea7c3e9a346761064e696.scandit", // ean13, ean8, upca, upce
    "/47fe40b164917635e99f9d917ea873df.scandit", // ean13, ean8, upca, upce
    "/c86520b1e03d20ad23c7aa3057bc00aa.scandit", // ean13, ean8, upca, upce
    "/8ca1870a78346f894973385bac861368.scandit", // itf
    "/d6bc81e9953262efe2ba28dc88a255c7.scandit", // itf
    "/f2cc6637d1f431587ae8f0050944b1f6.scandit", // itf
    "/3a749978f5d673142bdcb360f7f6f943.scandit", // msi-plessey
    "/5794f5949d313c1c3b8d0ad8235352a4.scandit", // msi-plessey
    "/9a5f9ee72580f702ea388b0b2b29ad06.scandit", // msi-plessey
  ].map((path) => {
    return `${BlurryRecognitionPreloader.writableDataPath}${path}`;
  });
  // From AndroidGeneric
  private static readonly highEndBlurryTableFiles: string[] = [
    "/190321966be83d9d4eb3ebef42e0425c.scandit", // codabar
    "/51e855045b2f56ecc18e92b1c53c302c.scandit", // codabar
    "/5e2464c47c50ac324766b4f7836a9238.scandit", // codabar
    "/7f95c7a85f7644081420026f011afc26.scandit", // codabar
    "/9768cd567a0813ef9e2b35377e5763b3.scandit", // codabar
    "/9da839200be5f945ae07ce56be4b519b.scandit", // codabar
    "/1e09ddd31d6b791f2aff1fc178fc0fa6.scandit", // code32, code39
    "/4e6cfc8f10105c1c88be188781e1fd09.scandit", // code32, code39
    "/777cff34a643cc67783abc5a2cd28028.scandit", // code32, code39
    "/7a47da9075339736d97d20e74743adb4.scandit", // code32, code39
    "/83b2f2f20564df0c4c3343abdd33ce2c.scandit", // code32, code39
    "/876aa038cde59f3bc554408ef6de5aba.scandit", // code32, code39
    "/525eb9a51a6d7a247a718bd47e8e6fca.scandit", // code93
    "/5c72db14fd540dd7ed0a1a8e03d1a08d.scandit", // code93
    "/61014b41bd1a00c842a881267d5b47bf.scandit", // code93
    "/748fd6c978b0f7e02fa4c5f481f69a92.scandit", // code93
    "/7db7b21c46a607367ee9993279d4bf06.scandit", // code93
    "/b5189294cd7b8c5428008b37a4ebee57.scandit", // code93
    "/28307ba88850bdbf0ca3c02bc00ce76c.scandit", // code128
    "/2f239cbc1915384192586bb52f1e20d5.scandit", // code128
    "/443c732a519cd45ae3de1b90eca2221a.scandit", // code128
    "/4a7685d7441e9ed9b08342273033d654.scandit", // code128
    "/5d777eae7a2b98a13183dbab6ab05f87.scandit", // code128
    "/bfdd27616e9e53ec1256e61025c87e4f.scandit", // code128
    "/0cf46df76c8afda2dd17eada4c0aa3d9.scandit", // ean13, ean8, upca, upce
    "/55c134f1aa08ae47b6f1101b03ff1369.scandit", // ean13, ean8, upca, upce
    "/7b5c8ef98b4497fe700a3647dcccc4e6.scandit", // ean13, ean8, upca, upce
    "/8d97762fcf3c987deeca8e790b124273.scandit", // ean13, ean8, upca, upce
    "/b2881842e74d4b75fa0dcbb2658f0da3.scandit", // ean13, ean8, upca, upce
    "/fc5e2552d2904a71a912dacaa0547efe.scandit", // ean13, ean8, upca, upce
    "/00918cc9b4ad74bf76111e9fa70e158e.scandit", // itf
    "/4f10a1584fa6bfa1af2bfc95f938d192.scandit", // itf
    "/61579472d3ab4998bfcc9e3070f39354.scandit", // itf
    "/a5b8d6eee7ccd778f4b42d840add2539.scandit", // itf
    "/ce62d7332b17011763bd79516d908235.scandit", // itf
    "/ed70de938d43e92a43f5176f0fb3aef0.scandit", // itf
    "/2dc97c75a0fafc59e91c76f766b8372d.scandit", // msi-plessey
    "/64a3982f73cd8050fdb4b1a6e8c07537.scandit", // msi-plessey
    "/6eb7c32c9bc81edaec9e816615538484.scandit", // msi-plessey
    "/866c3631e1963d133c8598b60675894d.scandit", // msi-plessey
    "/ce6c0d7ebc0081eeeb51c82beddba8a7.scandit", // msi-plessey
    "/ffd07d94597bc9622936112d5cbacbbe.scandit", // msi-plessey
  ].map((path) => {
    return `${BlurryRecognitionPreloader.writableDataPath}${path}`;
  });
  // Roughly ordered by priority
  private static readonly availableBlurryRecognitionSymbologies: Set<Barcode.Symbology> = new Set([
    Barcode.Symbology.EAN13, // Shared with EAN8, UPCA, UPCE
    Barcode.Symbology.EAN8, // Shared with EAN13, UPCA, UPCE
    Barcode.Symbology.CODE32, // Shared with CODE39
    Barcode.Symbology.CODE39, // Shared with CODE32
    Barcode.Symbology.CODE128,
    Barcode.Symbology.CODE93,
    Barcode.Symbology.INTERLEAVED_2_OF_5,
    Barcode.Symbology.MSI_PLESSEY,
    Barcode.Symbology.CODABAR,
    Barcode.Symbology.UPCA, // Shared with EAN8, EAN13, UPCE
    Barcode.Symbology.UPCE, // Shared with EAN8, EAN13, UPCA
  ]);

  private readonly eventEmitter: BlurryRecognitionPreloaderEventEmitter = new EventEmitter();
  private readonly preload: boolean;

  private queuedBlurryRecognitionSymbologies: Barcode.Symbology[] = Array.from(
    BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.values()
  );
  private readyBlurryRecognitionSymbologies: Set<Barcode.Symbology> = new Set();
  private engineWorker: DataCaptureWorker;

  private constructor(preload: boolean) {
    this.preload = preload;
  }

  public static async create(preload: boolean): Promise<BlurryRecognitionPreloader> {
    if (preload) {
      // Edge <= 18 doesn't support IndexedDB in blob Web Workers so data wouldn't be persisted,
      // hence it would be useless to preload blurry recognition as data couldn't be saved.
      // Verify support for IndexedDB in blob Web Workers.
      const browserName: string | undefined = BrowserHelper.userAgentInfo.getBrowser().name;
      if (browserName != null && browserName.includes("Edge")) {
        const worker: Worker = new Worker(
          URL.createObjectURL(
            new Blob([`(${BlurryRecognitionPreloader.workerIndexedDBSupportTestFunction.toString()})()`], {
              type: "text/javascript",
            })
          )
        );

        return new Promise((resolve) => {
          worker.onmessage = (message) => {
            worker.terminate();
            resolve(new BlurryRecognitionPreloader(message.data));
          };
        });
      }
    }

    return new BlurryRecognitionPreloader(preload);
  }

  // istanbul ignore next
  private static workerIndexedDBSupportTestFunction(): void {
    try {
      indexedDB.deleteDatabase("scandit_indexeddb_support_test");
      // @ts-ignore
      postMessage(true);
    } catch (error) {
      // @ts-ignore
      postMessage(false);
    }
  }

  public async prepareBlurryTables(): Promise<void> {
    let alreadyAvailable: boolean = true;
    if (this.preload) {
      try {
        alreadyAvailable = await this.checkBlurryTablesAlreadyAvailable();
      } catch (error) {
        // istanbul ignore next
        console.error(error);
      }
    }
    if (alreadyAvailable) {
      this.queuedBlurryRecognitionSymbologies = [];
      this.readyBlurryRecognitionSymbologies = new Set(
        BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies
      );
      this.eventEmitter.emit("blurryTablesUpdate", new Set(this.readyBlurryRecognitionSymbologies));
    } else {
      this.engineWorker = new Worker(URL.createObjectURL(dataCaptureWorkerBlob));
      this.engineWorker.onmessage = this.engineWorkerOnMessage.bind(this);
      DataCaptureLoader.load(this.engineWorker, true, true);
    }
  }

  public on(eventName: EventName, listener: ListenerFn): void {
    // istanbul ignore else
    if (eventName === "blurryTablesUpdate") {
      if (
        this.readyBlurryRecognitionSymbologies.size ===
        BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.size
      ) {
        listener(this.readyBlurryRecognitionSymbologies);
      } else {
        this.eventEmitter.on(eventName, listener);
      }
    }
  }

  public updateBlurryRecognitionPriority(scanSettings: ScanSettings): void {
    const newQueuedBlurryRecognitionSymbologies: Barcode.Symbology[] = this.queuedBlurryRecognitionSymbologies.slice();
    this.getEnabledSymbologies(scanSettings).forEach((symbology) => {
      const symbologyQueuePosition: number = newQueuedBlurryRecognitionSymbologies.indexOf(symbology);
      if (symbologyQueuePosition !== -1) {
        newQueuedBlurryRecognitionSymbologies.unshift(
          newQueuedBlurryRecognitionSymbologies.splice(symbologyQueuePosition, 1)[0]
        );
      }
    });
    this.queuedBlurryRecognitionSymbologies = newQueuedBlurryRecognitionSymbologies;
  }

  public isBlurryRecognitionAvailable(scanSettings: ScanSettings): boolean {
    const enabledBlurryRecognitionSymbologies: Barcode.Symbology[] = this.getEnabledSymbologies(scanSettings);

    return enabledBlurryRecognitionSymbologies.every((symbology) => {
      return this.readyBlurryRecognitionSymbologies.has(symbology);
    });
  }

  public getEnabledSymbologies(scanSettings: ScanSettings): Barcode.Symbology[] {
    return Array.from(BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.values()).filter((symbology) => {
      return scanSettings.isSymbologyEnabled(symbology);
    });
  }

  private createNextBlurryTableSymbology(): void {
    let symbology: Barcode.Symbology | undefined;
    do {
      symbology = this.queuedBlurryRecognitionSymbologies.shift();
    } while (symbology != null && this.readyBlurryRecognitionSymbologies.has(symbology));
    // istanbul ignore else
    if (symbology != null) {
      this.engineWorker.postMessage({
        type: "create-blurry-table",
        symbology,
      });
    }
  }

  private checkBlurryTablesAlreadyAvailable(): Promise<boolean> {
    return new Promise((resolve) => {
      const openDbRequest: IDBOpenDBRequest = indexedDB.open(BlurryRecognitionPreloader.writableDataPath);
      function handleErrorOrNew(this: IDBOpenDBRequest | IDBTransaction | IDBRequest | { error: Error }): void {
        openDbRequest?.result?.close();
        // this.error
        resolve(false);
      }

      openDbRequest.onupgradeneeded = () => {
        try {
          openDbRequest.result.createObjectStore(BlurryRecognitionPreloader.fsObjectStoreName);
        } catch (error) {
          // Ignored
        }
      };
      openDbRequest.onsuccess = () => {
        try {
          const transaction: IDBTransaction = openDbRequest.result.transaction(
            BlurryRecognitionPreloader.fsObjectStoreName,
            "readonly"
          );
          transaction.onerror = handleErrorOrNew;
          const storeKeysRequest: IDBRequest<IDBValidKey[]> = transaction
            .objectStore(BlurryRecognitionPreloader.fsObjectStoreName)
            .getAllKeys();
          storeKeysRequest.onsuccess = () => {
            openDbRequest.result.close();
            if (
              (highEndBlurryRecognition
                ? BlurryRecognitionPreloader.highEndBlurryTableFiles
                : BlurryRecognitionPreloader.defaultBlurryTableFiles
              ).every((file) => {
                return storeKeysRequest.result.indexOf(file) !== -1;
              })
            ) {
              return resolve(true);
            } else {
              return resolve(false);
            }
          };
          storeKeysRequest.onerror = handleErrorOrNew;
        } catch (error) {
          handleErrorOrNew.call({ error });
        }
      };
      openDbRequest.onblocked = openDbRequest.onerror = handleErrorOrNew;
    });
  }

  private engineWorkerOnMessage(ev: MessageEvent): void {
    const data: DataCaptureSentMessageData = ev.data;

    // istanbul ignore else
    if (data[1] != null) {
      switch (data[0]) {
        case "context-created":
          this.createNextBlurryTableSymbology();
          break;
        case "create-blurry-table-result":
          this.readyBlurryRecognitionSymbologies.add(data[1]);
          if (
            [Barcode.Symbology.EAN8, Barcode.Symbology.EAN13, Barcode.Symbology.UPCA, Barcode.Symbology.UPCE].includes(
              data[1]
            )
          ) {
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.EAN13);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.EAN8);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.UPCA);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.UPCE);
          } else if ([Barcode.Symbology.CODE32, Barcode.Symbology.CODE39].includes(data[1])) {
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.CODE32);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.CODE39);
          }
          this.eventEmitter.emit("blurryTablesUpdate", new Set(this.readyBlurryRecognitionSymbologies));
          if (
            this.readyBlurryRecognitionSymbologies.size ===
            BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.size
          ) {
            // Avoid data not being persisted if IndexedDB operations in WebWorker are slow
            setTimeout(() => {
              this.engineWorker.terminate();
            }, 250);
          } else {
            this.createNextBlurryTableSymbology();
          }
          break;
        // istanbul ignore next
        default:
          break;
      }
    }
  }
}
